































import mixins from 'vue-typed-mixins'

import selectorMixin from './selectorMixin'

import { HFormField, HSelect } from '@happytal/bo-ui-library'

export default mixins(selectorMixin).extend({
    components: {
        HFormField,
        HSelect
    },

    computed: {
        selectOptions(): Record<string, any>[] {
            return this.values.map(value => ({ ...value, $isDisabled: value.disabled }))
        },
    }
})
