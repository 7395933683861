












import Vue from 'vue'

import { HTooltip } from '@happytal/bo-ui-library'

export default Vue.extend({
  components: {
    HTooltip,
  },
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
})
