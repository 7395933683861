<template>
  <component
      :is="route ? 'router-link' : 'div'"
      class="back-section"
      :to="route"
      @click="onBackBtnClick"
  >
    <HBtn
      icon-only
      icon="ChevronLeftIcon"
      size="x-small" 
      :iconSize="18"
      class="back-btn"
      data-cy="back-btn"
    ></HBtn>
    <div class="back-label">{{ label }}</div>
  </component>
</template>

<script>
import {
  HBtn
} from '@happytal/bo-ui-library'

export default {
    name: 'BackButton',
    components: {
      HBtn
    },
    props: {
      label: String,
      route: Object
    },
    computed: {

    },
    methods: {
        onBackBtnClick (e) {
            this.$emit('click', e)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.back-section {
    display: inline-flex;
    align-items: center;
    margin: 0px 0px 14px 0px;
    cursor: pointer;

    @media (max-width: map-get($breakpoints, sm)) {
        margin: 0px 0px 14px 10px;
    }

    .back-label {
        margin: 0px 0px 0px 13px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 29px;
        color: rgba(var(--vs-primary), 1);
        user-select: none;
    }
}
</style>
