import Vue from 'vue'

export default Vue.extend({
    methods: {
        $_emitLoadRoute(value: boolean): void {
            this.$emit('load-route', value)
        },
    },

    created(){
        this.$_emitLoadRoute(false)
    }
})