import Vue, { PropType } from 'vue'

import { VariantValue } from '@/types/components'

export default Vue.extend({
    props: {
        code: String,
        label: String,
        type: {
            type: String,
            required: true,
            validator: val => ['TextDropdown', 'Toggles', 'Thumbnails'].includes(val)
        },
        image: String,
        values: {
            type: Array as PropType<VariantValue[]>,
            required: true
        },
        selected: {
            type: Object as PropType<VariantValue>
        },
        disableItemMessage: String
    },

    methods: {
        updateItemSelected(value: VariantValue): void {
            if (!value.disabled && value.id !== this.selected?.id) {
                this.$emit('input', value)
            }
        }
    }
})