






















































//TO DO: Change link "Modifier" by DS or lib component
import Vue, { PropType } from 'vue'

import { frequencies } from '@/constants/subscription'

import {
    HFormField,
    HCheckbox,
    HIcon,
    HPopover,
    HSelect
} from '@happytal/bo-ui-library'
import SubscriptionIcon from '@/components/icons/SubscriptionIcon.vue'

import { FrequencySelectOption } from '@/types/components'

export default Vue.extend({
    components: {
        HFormField,
        HCheckbox,
        HIcon,
        HPopover,
        HSelect,
        SubscriptionIcon,
    },

    props: {
        value: Boolean,
        frequency: {
            type:Object as PropType<FrequencySelectOption | null>
        },
        isCurrentSubscription: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'product'
        }
    },

    data(){
        return {
            subscriptionCheckbox: false
        }
    },

    computed: {
        iconColor(): string {
            if (this.isCurrentSubscription) return '#FF9F43'
            return '#17d3c3'
        },
        isProductPage(): boolean {
            return this.$route.params?.type === 'product'
        },
        frequenciesOptions(): FrequencySelectOption[] {
            return Object.entries(frequencies).map(
                ([key, value]) => ({
                    value: parseInt(key),
                    text: value
                })
            )
        },
        productLabel(): String {
            switch (this.type) {
                case 'service':
                    return 'service'
                default:
                    return 'produit'
            }
        }
    },

    methods: {
        onChangeValue(value: boolean): void {
            this.$emit('input:value', value)
        },
        onInputFrequency(value: FrequencySelectOption): void {
            this.$emit('input:frequency', value)
        }
    }
})
