<template>
  <div class="basket-overlay">
    <vs-button
      class="basket-btn"
      data-cy="basket-btn"
      color="primary"
      :disabled="disabled"
      @click="onBasketBtnClick"
      @mouseover="onBasketBtnHover"
      @mouseleave="onBasketBtnLeave"
    >
      <div class="basket-legend">
        <img class="basket-icon" src="@/assets/images/icons/shopping-cart.svg" />
        <div class="basket-quantity">{{ getProductCount() }}</div>
        <div v-if="$breakpoint.smAndUp" class="basket-price">{{ getDraftAmount() }} €</div>
      </div>
    </vs-button>

    <div
      class="button-popup draft-popup"
      data-cy="draft-popup"
      v-if="draftPopupVisible && showDraftPopup"
    >
      <div class="popup-header">
        <div class="popup-title">Mon panier</div>
      </div>
      <div class="popup-content">
        <div class="content">
          <div class="product-content">
            <div class="product-section" v-if="productAdded.frequency >= 1">
              Ajouts d’abonnements
            </div>
            <div class="product-section" v-else>
              Commandé une seule fois
            </div>
            <div class="products">
              <div
                class="product-item"
              >
                <div class="product-photo">
                  <img class="product-image" :src="productAdded.photo" />
                </div>
                <div class="product-infos">
                  <div class="product-title" data-cy="draft-popup-title">{{ productAdded.title }}</div>
                  <div class="product-legend" v-html="productAdded.legend" data-cy="draft-popup-legend"></div>
                  <div class="product-description" v-html="productAdded.description" data-cy="draft-popup-description"></div>
                  <div class="quantity-actions">
                    <div class="quantity-label">Qté : {{ productAdded.quantity }}</div>
                  </div>
                  <div class="product-actions">
                      <div class="product-amount">{{ productAdded.amount }} €</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="button-popup basket-popup"
      v-if="popupVisible"
      @mouseover="onPopupHover"
      @mouseleave="onPopupLeave"
    >
      <div class="popup-header">
        <div class="popup-title">Mon panier</div>
      </div>
      <div class="popup-content">
        <div
          class="content-empty"
          v-if="isDraftEmpty()"
           data-cy="draft-popup-empty"
        >
          <div class="empty-text">
            Il n'y a aucun article dans<br />votre panier
          </div>
        </div>
        <div
          class="content"
          v-if="!isDraftEmpty()"
        >
          <div
            class="product-content"
            v-if="products.once.length > 0"
          >
            <div class="product-section">
              Commandé une seule fois
            </div>
            <div class="products" :class="{'products-max': isProductHeightMax() }">
              <div
                class="product-item"
                v-for="(product, productIndex) in products.once"
                :key="productIndex"
              >
                <div class="product-photo">
                  <img class="product-image" :src="product.photo" />
                </div>
                <div class="product-infos">
                  <div class="product-title">{{ product.title }}</div>
                  <div
                    class="quantity-actions"
                    v-if="product.type == 'product'"
                  >
                    <div class="quantity-label">Qté :</div>
                    <HQuantity
                      class="quantity-select"
                      :value="product.quantity"
                      :min="1"
                      :max="10"
                      @input="(quantity) => { onProductQuantityChange(product, quantity) }"
                    />
                  </div>
                  <div class="product-actions">
                      <div class="product-amount">{{ product.amount }} €</div>
                      <img
                        class="delete-btn"
                        src="@/assets/images/ehpad/trash.svg"
                        @click="onProductDelete(product)"
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="product-content"
            v-if="products.subscription.length > 0"
          >
            <div class="product-section">
              Ajouts d’abonnements
            </div>
            <div class="products" :class="{'products-max': isProductHeightMax() }">
              <div
                class="product-item"
                v-for="(product, productIndex) in products.subscription"
                :key="productIndex"
              >
                <div class="product-photo">
                  <img class="product-image" :src="product.photo" />
                </div>
                <div class="product-infos">
                  <div class="product-title">{{ product.title }}</div>
                  <div
                    class="quantity-actions"
                    v-if="product.type == 'product'"
                  >
                    <div class="quantity-label">Qté :</div>
                    <HQuantity
                      class="quantity-select"
                      :value="product.quantity"
                      :min="1"
                      :max="10"
                      @input="(quantity) => { onProductQuantityChange(product, quantity) }"
                    />
                  </div>
                  <div class="product-actions">
                      <div class="product-amount">{{ product.amount }} €</div>
                      <img
                        class="delete-btn"
                        src="@/assets/images/ehpad/trash.svg"
                        @click="onProductDelete(product)"
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="popup-footer">
            <HBtn
              class="buy-btn"
              @click="onBuyBtnClick"
            >Commander</HBtn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import Routes from '@/constants/routes'

import {
  getServiceOptionsAsHtml,
  getProductOptionsAsHtml
} from '@/modules/services'

import {
  HBtn,
  HQuantity
} from '@happytal/bo-ui-library'

export default {
    props: {
      residentId: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      showDraftPopup: {
        type: Boolean,
        default: true
      }
    },
    components: {
        HBtn,
        HQuantity
    },
    data () {
        return {
          popupVisible: false,
          popupHover: false,
          quantityOptions: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10
          ],
          products: []
        }
    },
    computed: {
       draftPopupVisible () {
         return _.get(this.getDraftPopup(), 'visible', false)
       },
       productAdded () {
         const product = _.get(this.getDraftPopup(), 'product', {})
         const description = product.type == 'product' ? this.getMetaProduct(product.meta) : this.getServiceOptions(product)
         return {
           photo: product.photo,
           title: product.title,
           description,
           quantity: product.quantity,
           frequency: product.frequency,
           amount: (product.price * product.quantity).toFixed(2)
         }
       }
    },
    methods: {
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getDraftPopup () {
            return this.$store.getters['dataDrafts/getDraftPopup']()
        },
        getFirstDraft (userId, residentId, draftType) {
            return this.$store.getters['dataDrafts/getFirstDraft'](userId, residentId, draftType)
        },
        getCurrentDraft (draftType) {
            return this.getFirstDraft(this.getUserId(), this.residentId, draftType)
        },
        getProductCount () {
            return this.$store.getters['dataDrafts/getProductCount'](this.getUserId(), this.residentId)
        },
        getDraftAmount () {
            return this.$store.getters['dataDrafts/getDraftAmount'](this.getUserId(), this.residentId)
        },
        getProducts (draftType, productType) {
            return this.$store.getters['dataDrafts/getProducts'](this.getUserId(), this.residentId, draftType, productType)
        },
        isDraftEmpty () {
            return this.getProductCount() < 1
        },
        isProductHeightMax () {
            const onceCount = _.get(this.products, 'once.length', 0)
            const subscriptionCount = _.get(this.products, 'subscription.length', 0)
            const allCount = onceCount + subscriptionCount
            return allCount > 0 && (onceCount == 0 || subscriptionCount == 0)
        },
        getMetaProduct(product) {
            return getProductOptionsAsHtml(product)
        },
        getServiceOptions(service) {
            return getServiceOptionsAsHtml(service)
        },
        onBasketBtnClick (e) {
            this.$emit('click', e)
        },
        onBasketBtnHover (e) {
          if (this.disabled || this.popupVisible) {
            return
          }
          this.updateProducts()
          this.popupVisible = true
          this.$store.commit('dataDrafts/SET_POPUP_VISIBLE', {
              visible: false
          })
        },
        onBasketBtnLeave (e) {
          if (this.disabled) {
            return
          }
          setTimeout(() => {
              if (this.popupHover) {
                return
              }
              this.popupVisible = false
            },
            2000
          )
        },
        onPopupHover (e) {
          this.popupHover = true
        },
        closeBasketPopup () {
          this.popupVisible = false
          this.popupHover = false
        },
        onPopupLeave (e) {
          this.closeBasketPopup()
        },
        onBuyBtnClick (e) {
          this.$router.push({
            name: Routes.ResidentStoreBasket,
            params: {
              resident_id: this.residentId
            }
          })
        },
        onProductQuantityChange (product, quantity) {
          const draft = this.getCurrentDraft(product.draftType)
          if (!draft) {
              return
          }
          var productToUpdate = _.clone(product)
          productToUpdate.quantity = quantity
          this.$store.commit('dataDrafts/ADD_OR_UPDATE_PRODUCT', {
              draftId: draft.id,
              product: productToUpdate
          })
          this.$store.commit('dataDrafts/SAVE_DRAFTS')
          this.updateProducts()
        },
        onProductDelete (product) {
          const draft = this.getCurrentDraft(product.draftType)
          if (!draft) {
              return
          }
          this.$store.commit('dataDrafts/DELETE_PRODUCT', {
              draftId: draft.id,
              product
          })
          this.$store.commit('dataDrafts/SAVE_DRAFTS')
          this.updateProducts()
          if (this.isDraftEmpty()) {
            setTimeout(() => {
                this.closeBasketPopup()
              },
              2000
            )
          }
        },
        updateProducts () {
          const products = {}
          products.once = _.map(this.getProducts('once'), (product) => {
            const amount = product.price * product.quantity
            return {
              ...product,
              draftType: 'once',
              amount: amount.toFixed(2)
            }
          })
          products.subscription = _.map(this.getProducts('subscription'), (product) => {
            const amount = product.price * product.quantity
            return {
              ...product,
              draftType: 'subscription',
              amount: amount.toFixed(2)
            }
          })
          this.products = products
        }
    }
}
</script>

<style lang="scss" scoped>
.basket-overlay {
    position: relative;
}
.basket-btn {
    max-width: 145px;
    box-shadow: -1px 1px 8px rgba(126, 114, 242, 0.2);
    padding: 5px 13px 2px 13px !important;
}
.basket-legend {
    display: flex;
    align-items: center;

    .basket-icon {

    }
    .basket-quantity {
        margin: 0px 0px 0px 4px;
        color: #FFFFFF;
    }
    .basket-price {
        width: 76px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: #FFFFFF;
    }
}
.button-popup {
  position: absolute;
  top: 40px;
  right: 0px;
  width: 300px;
  padding: 15px 18px 15px 18px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 3;
}

.popup-header {
  padding: 0px 0px 10px 0px;

  .popup-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: rgba(var(--vs-primary), 1);
  }
}
.popup-content {
  .content-empty {
    .empty-text {
      padding: 5px 0px 5px 0px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
    }
  }
  .content {

    .product-section {
      padding: 3px 10px 3px 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      background: #F1F1F1;
      border-radius: 5px 5px 0px 0px;
    }

    .products-max {
      max-height: 510px !important;
    }
    .products {
      max-height: 255px;
      overflow-y: auto;

      .product-item {
        display: flex;
        padding: 12px 0px 12px 0px;
        border-bottom: 1px solid #F2F2F2;
        color: #2C2C2C;

        .product-photo {
          width: 75px;
          .product-image {
            width: 73px;
          }
        }
        .product-infos {
          width: 170px;
          margin: 0px 0px 0px 11px;

          .product-title {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
          }
          .product-legend {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
          }
          .product-description {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
          }
          .quantity-actions {
            display: flex;
            align-items: center;

            .quantity-label {
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 16px;
            }
            .quantity-select {
              margin: 0px 0px 0px 10px;
            }
          }
          .product-actions {
            display: flex;
            align-items: center;
            margin: 10px 0px 0px 0px;

            .product-amount {
              flex-grow: 1;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              color: rgba(var(--vs-primary), 1);
            }
            .delete-btn {
              cursor: pointer;
            }
          }
        }
      }
      .product-item:last-child {
        border: none;
      }
    }
  }
}
.popup-footer {
  padding: 10px 0px 0px 0px;
  text-align: center;

  .buy-btn {

  }
}
</style>
