


























































import Vue, { PropType } from 'vue'
import { mapState, mapActions } from 'vuex'

import Routes from '@/constants/routes'

import {
    HBtn,
    HQuantity
} from '@happytal/bo-ui-library'
import SeparatorLine from '@/components/SeparatorLine.vue'
import FormErrorMessage from '@/components/forms/FormErrorMessage.vue'
import CatalogItemSubscription from './CatalogItemSubscription.vue'
import CatalogItemSuggestionEmitter from './CatalogItemSuggestionEmitter.vue'
import CatalogAnchorToAddBtn from './CatalogAnchorToAddBtn.vue'
import ConditionalTooltip from '@/components/ConditionalTooltip.vue'

export default Vue.extend({
    components: {
        HBtn,
        HQuantity,
        SeparatorLine,
        FormErrorMessage,
        CatalogItemSubscription,
        CatalogItemSuggestionEmitter,
        CatalogAnchorToAddBtn,
        ConditionalTooltip
    },

   props: {
       settingError: String,
       suggestionEnabled: Boolean
   },

   data() {
       return {
           errorSubscription: ''
       }
   },

    computed: {
        type(): string {
            switch(this.$route.name) {
                case Routes.CatalogService:
                    return 'service'
                case Routes.CatalogProduct:
                case Routes.CatalogVariant:
                default:
                    return 'product'
            }
        },

        ...mapState(
            'dataBuyBox',
            [
                'isSuggestionMode',
                'suggestionEmitter',
                'subscriptionMode',
                'subscriptionFrequency',
                'isCurrentSubscription',
                'quantity',
                'amount',
                'isVisibleAddBtn'
            ]
        ),

        suggestionEmitterPlaceholder(): string {
            return this.$store.state.dataUser.user?.displayName || ''
        },

        totalAmount(): number {
            return this.$store.getters['dataBuyBox/getTotalAmount']
        },

        isAddBtnDisabled(): Boolean {
            return this.isSuggestionMode && !this.suggestionEnabled
        },

        dataCyAddBtn(): string {
            if (this.isSuggestionMode)
                return 'btn-send-suggestion'
            else
                return 'btn-add-to-basket'
        },
        textAddBtn(): string {
            if (this.isSuggestionMode)
                return 'Envoyer la suggestion'
            else
                return 'Ajouter au panier'
        },

        dispalyAnchorToAddBtn(): boolean {
            return this.$breakpoint.mdAndDown && !this.isVisibleAddBtn
        },

        error(): string {
            return this.errorSubscription || this.settingError || ''
        }
    },

    mounted(){
        if (this.$aclChecker.some(this.$acl, ['canEmitSuggestions',])) {
            this.$store.dispatch('dataBuyBox/updateIsSuggestionMode', true)
        }
    },

    methods: {
        ...mapActions(
            'dataBuyBox',
            [
                'updateSuggestionEmitterName',
                'updateSubscriptionMode',
                'updateSubscriptionFrequency',
                'updateQuantity',
                'updateIsVisibleAddBtn'
            ]
        ),

        onClickAddBtn(): void {
            //Check if subscription error
            if (this.subscriptionMode && !this.subscriptionFrequency) {
                this.errorSubscription = `Veuillez choisir une fréquence d'abonnement`
                return
            }

            //Else emit correct event and clear error
            this.errorSubscription = ''

            if (this.isSuggestionMode)
                this.$emit('click:send-suggestion')
            else
                this.$emit('click:add-to-basket')


        },

        scrollToBuyBox(): void {
            const buyBox = this.$refs.buyBox as HTMLElement
            buyBox.scrollIntoView({behavior: "smooth"})
        },
    }
})
