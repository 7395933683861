



































import Vue from 'vue'

export default Vue.extend({
    props: {
        brand: String,
        shop: String,
        title: String,
        type: {
            type: String,
            default: 'product',
            validator: val => ['product', 'service'].includes(val)
        },
        price: [Number, String],
        displayLinkDetail: Boolean,
        shortDescription: String
    },

    computed: {
        shopStartText(): string {
            return this.type === 'product' ? 'Vendu par' : 'Prestation faite par'
        }
    }
})
