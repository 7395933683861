




import Vue from 'vue'

export default Vue.extend({
    props: {
        color: {
            type: String,
            default: '#17d3c3'
        }
    },
})
