







import Vue from 'vue'

import { HIcon } from '@happytal/bo-ui-library'

export default Vue.extend({
    components: {
        HIcon
    },

    props: {
        message: String
    }
})
