












































import mixins from 'vue-typed-mixins'

    import selectorMixin from './selectorMixin'

    import { HFormField, HPopover, HCard, HIcon, HCarousel } from '@happytal/bo-ui-library'

    export default mixins(selectorMixin).extend({
        components: {
            HFormField,
            HPopover,
            HCard,
            HIcon,
            HCarousel
        },

        data(){
            return {
                colorsMap: {
                    'Rouge': '#d62828',
                    'Violet': '#9d4edd',
                    'Vert': '#2bab42',
                    'Orange': '#f77f00',
                    'Bleu': '#3a86ff',
                    'Noir': '#333333',
                    'Blanc': '#ffffff',
                    'Marron': '#9d6b53',
                    'Rose': '#ff86c8',
                    'Gris': '#e2e2e2'
                }
            }
        },

        computed: {
            selectOptions(): Record<string, any>[] {
                return this.values.map(value => ({ ...value, $isDisabled: value.disabled }))
            },
        },
    })
