













import Vue, { PropType } from 'vue'

import Routes from '@/constants/routes'

import CatalogBreadcrumb from './CatalogBreadcrumb.vue'
import BasketButton from '@/components/BasketButton.vue'

import { Location } from 'vue-router'
import { CatalogBreadcrumbItem } from '@/types/components'

export default Vue.extend({
    components: {
        CatalogBreadcrumb,
        BasketButton
    },
    props: {
        residentId: {
            type: String,
            required: true
        },
        breadcrumbItems: {
            type: Array as PropType<CatalogBreadcrumbItem[]>,
            required: true
        },
        disableBasketButton: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onBasketBtnClick(): void {
            this.$emit('click:basket')
            this.$router.push({
                name: Routes.ResidentStoreBasket,
                params: {
                    resident_id: this.residentId
                }
            })
        },
    }
})
