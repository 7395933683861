
























import Vue, { PropType } from 'vue'

import { HIcon } from '@happytal/bo-ui-library'

import { CatalogBreadcrumbItem } from '@/types/components'

export default Vue.extend({
    components: {
        HIcon
    },
    props: {
        items: {
            type: Array as PropType<CatalogBreadcrumbItem[]>
        },
        itemForMobile: {
            type: Object as PropType<CatalogBreadcrumbItem>
        }
    },
})
