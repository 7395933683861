<template>
    <HPopin
        :value="value"
        @input="close"
        width="medium"
        class="suggestion-warning-popin"
    >
        <template #header>
            Associer un référent
        </template>
        <div class="text-content">
            <p>
                Attention, ce résident n’a pas de référent.<br />
                Pour pouvoir faire des suggestions, nous vous invitons à
                compléter son profil pour ajouter un référent.
            </p>
        </div>
        <template #actions>
            <HBtn
                @click="onProfileBtnClick"
                style-type="primary"
                class="profile-btn"
                data-cy="profile-btn"
            >Accéder au profil</HBtn>
        </template>
    </HPopin>
</template>

<script>
    import {
      HPopin,
      HBtn
    } from "@happytal/bo-ui-library"

    export default {
        name: 'SuggestionWarningPopin',
        model: {
            prop: 'value',
            event: 'input'
        },
        components: {
            HPopin,
            HBtn
        },
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            close () {
                this.$emit('input', false)
            },
            onProfileBtnClick (e) {
                this.close()
                this.$emit('profile')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .suggestion-warning-popin::v-deep {

        .text-content {
            font-style: normal;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #000000;
        }
        .h-popin__dialog {
            min-height: 230px;
        }
        .h-popin__actions {

        }
    }
</style>
