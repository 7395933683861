






















import Vue from 'vue'

import {
    HFormField,
    HPopover,
    HIcon,
    HInput
} from '@happytal/bo-ui-library'

export default Vue.extend({
    components: {
        HFormField,
        HPopover,
        HIcon,
        HInput
    },

    props: {
        value: String,
        placeholder: String,
        disabled: Boolean
    },

    methods: {
        onInputEmitterName(value: string): void {
            this.$emit('input', value)
        }
    }
})
